import axios from 'axios';
import toast from 'react-hot-toast';
import { getCookie } from '../../../../utils/utility';
import { updateConfiguredGroups } from '../appSlice';

class AppGroupStateFactory {
  static async updateGroupApplicationSettings(
    dispatch: any,
    app: string,
    groupSettings: any,
  ) {
    var step = process.env.REACT_APP_GROUP_SETTINGS_SCREEN;
    switch (app) {
      case process.env.REACT_APP_OFFICE365_KEY:
      case process.env.REACT_APP_GOOGLE_KEY:
      case process.env.REACT_APP_SLACK_KEY:
      case process.env.REACT_APP_SHOPIFY_PLUS_KEY:
      case process.env.REACT_APP_SHOPIFY_NON_PLUS_KEY:
      case process.env.REACT_APP_ATLASSIAN_KEY:
      case process.env.REACT_APP_GITHUB_KEY: {
        const token = await getCookie('token');
        const group = {
          identifier: groupSettings.identifier,
          _id: groupSettings._id,
          description: groupSettings.description,
          policy: groupSettings.policy,
          blockApps: groupSettings.blockApps,
          allowedApps: groupSettings.allowedApps,
          restrictedApps: groupSettings.restrictedApps,
          customRestrictionApps: groupSettings.customRestrictionApps,
          atlassian: {
            jiraBoard: groupSettings.atlassian.jiraBoard,
            defaultJiraAccess: groupSettings.atlassian.defaultJiraAccess,
            confluenceBoard: groupSettings.atlassian.confluenceBoard,
            defaultConfluenceAccess:
              groupSettings.atlassian.defaultConfluenceAccess,
          },
          office365: {
            onedrive: {
              disableFileCopy: groupSettings.office365.onedrive.disableFileCopy,
              disableFileDelete: groupSettings.office365.onedrive.disableFileDelete,
            },
            sharepoint: {
              disableFileCopy: groupSettings.office365.sharepoint.disableFileCopy,
              disableFileDelete: groupSettings.office365.sharepoint.disableFileDelete,
            },
          },
        };
        if (
          !groupSettings
          || !groupSettings.identifier
          || !groupSettings.description
          || !groupSettings.policy
        ) {
          toast.error('Please fill all required fields');
        } else {
          await axios
            .post(
              `${process.env.REACT_APP_NODE_BACKEND_URL}/api/update-group-settings`,
              {
                group_details: group,
                type: groupSettings.type,
                app,
              },
              {
                headers: {
                  Authorization: token,
                },
              },
            )
            .then(async (response) => {
              step = process.env.REACT_APP_CONFIGURATION_COMPLETED;
              await dispatch(updateConfiguredGroups(response.data.GroupData));
              toast.success('Group Configured Successfully');
            })
            .catch((error) => {
              toast.error(error?.response?.data);
            });
        }
        return step;
      }

      default:
        break;
    }

    return step;
  }
}

export { AppGroupStateFactory };
